import { Component, HostListener, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { PushNotificationsService } from '@push-notifications';

import { GetUserInfo } from './auth/store/login.actions';
import { ReloadDialogComponent } from './layout/reload-dialog/reload-dialog.component';
import { DeviceService } from './modules/device/device.service';
import { isNewVersion } from './util/pwa';
import { NavigationEnd, Router } from '@angular/router';
import { SentryLoggerService } from './services/sentry-logger.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  private readonly deviceService = inject(DeviceService);
  private readonly dialog = inject(MatDialog);
  private readonly pushNotificationsService = inject(PushNotificationsService);
  private readonly router = inject(Router);
  private readonly sentryLoggerService = inject(SentryLoggerService);
  private readonly store$ = inject(Store);
  private readonly swUpdateService = inject(SwUpdate);

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResize(event: { currentTarget: { innerWidth: any } }) {
    this.deviceService.updateDeviceInfo(event.currentTarget.innerWidth);
  }

  constructor() {
    this.initPushNotifications();
    this.watchServiceWorkerUpdate();
  }

  ngOnInit(): void {
    this.deviceService.updateDeviceInfo(window.innerWidth);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pageName = event.urlAfterRedirects;
        this.sentryLoggerService.logPageView(pageName);
      }
    });
  }

  initPushNotifications(): void {
    this.pushNotificationsService.init().subscribe(() => {
      this.store$.dispatch(new GetUserInfo());
    });
  }

  watchServiceWorkerUpdate(): void {
    this.swUpdateService.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'VERSION_READY') {
        if (!isNewVersion(event)) {
          return;
        }

        this.dialog.open(ReloadDialogComponent, {
          data: {
            message: 'reload-page.message',
          },
        });
      }
    });
  }
}
