import { Model } from 'ngx-super-model';
import { CompanyDTO } from 'src/app/core/company/interfaces/company.interface';
import { MachineDTO } from 'src/app/core/machines/interfaces/machine.interface.model';

import { LicenseStatus } from '../enums/license-status.enum';
import { LicenseDTO } from '../interfaces/licenses.interface';

export class Licenses extends Model<LicenseDTO> {
  id: number | null = null;
  company: CompanyDTO | null = null;
  licenseStart: string | null = null;
  licenseEnd: string | null = null;
  machineSlots: number | null = null;
  status: LicenseStatus | null = null;
  type: string | null = null;
  machineSlotsAssigned: string | null = null;
  machinesAssigned?: Array<MachineDTO> = [];
  description?: string | null = null;
}
