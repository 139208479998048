import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { isSet } from 'src/app/util/util';

import { CellType } from '../../enum/cell-type.enum';
import { TableButton } from '../../interfaces/table-config.interface';
import { TableMoreOptions } from '../../interfaces/table-more-options.interface';
import { CellConfig } from '../../models/cells.config';
import { AbstractRowComponent } from '../abstract-row/abstract-row.component';

@Component({
  selector: 'tr[jm-row-body]',
  templateUrl: './jm-row-body.component.html',
})
export class JmRowBodyComponent extends AbstractRowComponent {
  @Input() rowData: any;
  @Input() expanded = false;
  @Input() loading!: boolean;
  @Input() moreOptions: Array<TableMoreOptions> = [];

  @Input() deleteButton: TableButton = {
    active: true,
    keyForDisabling: 'deletable',
  };
  @Input() dequeueButton: TableButton = {
    active: true,
    keyForDisabling: 'deletable',
  };

  @Input()
  matMenuTemplate?: TemplateRef<any>;

  @Output() optionsSelected: EventEmitter<{ option: TableMoreOptions; data: any }> = new EventEmitter<{
    option: TableMoreOptions;
    data: any;
  }>();

  @Output() recordMoreOptions: EventEmitter<any> = new EventEmitter<any>();

  readonly cellType = CellType;

  getExtraMobileCell(field: string): CellConfig | null {
    const column = this.columns.find((value) => value.field === field);
    if (isSet(column)) {
      return column;
    }

    return null;
  }

  onMoreOptions(event: MouseEvent, rowData: ObjectLiteral): void {
    event.stopPropagation();
    this.recordMoreOptions.emit(rowData);
  }
}
