import { cloneDeep } from 'lodash-es';
import { Model } from 'ngx-super-model';
import { map, take } from 'rxjs';
import { AbstractCRUDService } from 'src/app/core/abstract-crud.service';
import { DeviceService } from 'src/app/modules/device/device.service';
import { tableFilterChanged } from 'src/app/store/application.actions';
import { getTableFilters } from 'src/app/store/application.selector';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { flatten } from 'src/app/util/object.helper';

import { ChangeDetectorRef, Component, inject, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isSet } from '@util';

import { JMTableError } from '../../errors/table.error';
import { CustomLazyLoadEvent } from '../../interfaces/lazy-load-event';
import { PageDetails } from '../../interfaces/page-details.interface';
import { filtersToSearchCriteria, queryParamsToSearchCriteria, searchCriteriaToFilters } from '../../mappers/filters.mapper';
import { CellConfig } from '../../models';
import { SearchCriteria } from '../../models/search-criteria.config';
import { TableConfig } from '../../models/table.config';
import { TableLazyLoadEvent } from 'primeng/table';

@UntilDestroy()
@Component({ template: '' })
export class AbstractTableComponent<M extends Model<D>, D extends ObjectLiteral> implements OnInit {
  translateService = inject(TranslateService);
  dialog = inject(MatDialog);

  data: Array<M> = [];
  loading = true;
  pageDetails: PageDetails | null = null;
  searchCriteria: SearchCriteria = new SearchCriteria();
  tableSearchConfig: CustomLazyLoadEvent = {};
  tableConfig!: TableConfig;

  protected cd: ChangeDetectorRef;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected store$: Store;

  protected deviceService = inject(DeviceService);

  constructor(protected service: AbstractCRUDService<M, D>, protected injector: Injector) {
    this.cd = this.injector.get(ChangeDetectorRef);
    this.router = this.injector.get(Router);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.store$ = this.injector.get(Store);
  }

  ngOnInit(): void {
    if (!isSet(this.tableConfig?.id)) {
      throw new JMTableError('Table id in Table config is mandatory!!!');
    }

    this.loadSearchCriteria(this.tableConfig);
  }

  loadSearchCriteria(tableConfig: TableConfig) {
    this.store$
      .select(getTableFilters(tableConfig.id))
      .pipe(
        take(1),
        map((searchCriteria) => cloneDeep(searchCriteria)),
        untilDestroyed(this)
      )
      .subscribe((searchCriteria: SearchCriteria | null) => {
        const search = this.activatedRoute.snapshot.queryParamMap.get('search');

        // Browser navigation. No searchCriteria in state, take from URL if present.
        if (!searchCriteria && search) {
          const searchCriteriaFromURL = queryParamsToSearchCriteria(search, tableConfig);
          this.tableSearchConfig = this.mapSearchCriteriaToFilters(searchCriteriaFromURL, tableConfig);
          this.store$.dispatch(tableFilterChanged({ id: tableConfig.id, searchCriteria: searchCriteriaFromURL }));
          return;
        }

        // Angular navigation no state no URL
        if (!searchCriteria) {
          this.tableSearchConfig = this.mapSearchCriteriaToFilters(new SearchCriteria(), tableConfig);
          return;
        }

        // Angular navigation with state
        this.tableSearchConfig = this.mapSearchCriteriaToFilters(searchCriteria, tableConfig);
      });
  }

  loadMore(event: TableLazyLoadEvent): void {
    if (this.pageDetails?.isLast === true) {
      return;
    }

    if (!isSet(this.tableConfig)) {
      return;
    }

    this.loadMoreData(event, this.tableConfig);
  }

  onLazyLoadChanged(event: TableLazyLoadEvent): void {
    if (!isSet(this.tableConfig)) {
      return;
    }

    this.searchCriteria = this.mapFiltersToSearchCriteria(event, this.tableConfig);

    if (this.tableConfig.updateUrl) {
      this.updateUrl(this.searchCriteria);
    }

    if (this.tableConfig.id) {
      this.store$.dispatch(tableFilterChanged({ id: this.tableConfig.id, searchCriteria: this.searchCriteria }));
    }

    if (!this.tableConfig.autoLoad) {
      return;
    }
    this.loadData();
  }

  onChangeColumnSettings(cells: Array<CellConfig>): void {
    if (!isSet(this.tableConfig?.cellsConfig)) {
      return;
    }

    this.tableConfig.cellsConfig = cloneDeep(cells);
  }

  loadData(): void {
    this.loading = true;
    this.data = [];

    this.cd.detectChanges();
    this.service
      .getAll(this.searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.data = res.records;
          this.pageDetails = res.pageDetails;
          this.loading = false;
          this.cd.detectChanges();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  loadMoreData(event: TableLazyLoadEvent, tableConfig: TableConfig): void {
    this.loading = true;
    this.cd.markForCheck();

    const searchCriteria = this.mapFiltersToSearchCriteria(event, tableConfig);
    this.service
      .getAll(searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.data = [...this.data, ...res.records];
          this.pageDetails = res.pageDetails;
          this.cd.markForCheck();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  /**
   * Override in child class if additional mapping is needed when the filters are initially loaded from state or URL
   * @param searchCriteria
   * @param tableConfig
   * @returns
   */
  protected mapSearchCriteriaToFilters(searchCriteria: SearchCriteria, tableConfig: TableConfig): CustomLazyLoadEvent {
    return searchCriteriaToFilters(searchCriteria, tableConfig);
  }

  /**
   * Override if additional mapping is needed before searchCriteria is saved to store and sent to backend
   * @param event
   * @param tableConfig
   * @returns
   */
  protected mapFiltersToSearchCriteria(event: TableLazyLoadEvent, tableConfig: TableConfig) {
    return filtersToSearchCriteria(event, tableConfig);
  }

  protected updateUrl(searchCriteria: SearchCriteria) {
    const flattenSearch = flatten(searchCriteria);

    const search = new URLSearchParams(flattenSearch).toString();

    this.router.navigate([], {
      queryParams: { search },
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }

  protected deleteRecord(id: number): void {
    this.service
      .delete(id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadData();
      });
  }
}
