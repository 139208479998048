import moment from 'moment';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { isSet } from 'src/app/util/util';

import { AbstractControl, FormArray, ValidatorFn, Validators as NgValidators } from '@angular/forms';

import { ValidationMessage } from './validation-message';

/**
 * Custom  validators and wrapper for internal Angular validators
 */
export class Validators {
  static readonly emailValidationPattern = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-z0-9.-]+\.[a-z]{2,}$/;
  static readonly alphaNumericRegex = /^[a-zA-Z0-9_]*$/i;
  static readonly mconNotValidCharacters = /[\\/:*"<>\|]/;

  /**
   * Validator that requires the control's value to be less than or equal to the provided number.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static min(min: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const validator = NgValidators.min(min);
      if (validator(control)) {
        return new ValidationMessage('Number should not be lower than ' + min + '.', validator);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to be less than or equal to the provided number.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static max(max: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const validator = NgValidators.max(max);
      if (validator(control)) {
        return new ValidationMessage('Number should not be larger than ' + max + '.', validator);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control have a non-empty value.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static required(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.required(control);
        if (validator) {
          return new ValidationMessage('Field is required.', validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value be true. This validator is commonly
   * used for required checkboxes.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static requiredTrue(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.requiredTrue(control);
        if (validator) {
          return new ValidationMessage('Field is required.', validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a valid email pattern
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static email(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (!value || !control.value) {
        return null;
      }

      const valid = Validators.emailValidationPattern.test(control.value);

      if (!valid) {
        return new ValidationMessage('Email is not in valid format.', { email: true });
      }
      return null;
    };
  }

  /**
   * Validator that requires the length of the control's value to be greater than or equal
   * to the provided minimum length.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.minLength(minLength);
      if (v(control)) {
        return new ValidationMessage('Field minimum length is ' + minLength, v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the length of the control's value to be less than or equal
   * to the provided maximum length.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const validator = NgValidators.maxLength(maxLength);

      if (validator(control)) {
        return new ValidationMessage('The maximum length is ' + maxLength + ' characters.', validator);
      }
      return null;
    };
  }

  /**
   * Validator that requires the length of the control's value to be equal
   * to the provided length.
   * @param equalLength Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static exactLength(equalLength: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (control.value.length !== equalLength) {
        return new ValidationMessage('Field must be exactly' + equalLength + ' ', {
          equalLength: true,
        });
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a regex pattern.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static pattern(pattern: string | RegExp): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const validator = NgValidators.pattern(pattern);
      if (validator(control)) {
        return new ValidationMessage(`The value of this field did not match the pattern. (${pattern})`, validator);
      }
      return null;
    };
  }

  /**
   * Validator that requires validated control and provided other control to match.
   * Enables validation message override
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static equalTo(value: any, currentControl: any, controls: Array<any>): ValidatorFn {
    return (control: any): null | ValidationMessage => {
      if (!value || !control.value || !control.parent) {
        return null;
      }

      let compareFieldId = '';
      let message = '';
      let showError = true;

      if (typeof value === 'string') {
        message = `Field ${currentControl.label} must match ${controls.find((contr) => contr.id === value).label}`;
        compareFieldId = value;
      }

      if (typeof value === 'object') {
        compareFieldId = value.field;
        message = value.message;
        showError = value.showError;
      }

      if (!control.parent.get(compareFieldId).value) {
        return null;
      }

      const valid = control.value === control.parent.get(compareFieldId).value;
      const validationMessage = new ValidationMessage(message, {
        equalTo: true,
      });

      const compareField = control.parent.get(compareFieldId);

      if (valid) {
        compareField.setErrors(null);
        return null;
      }

      if (showError === false) {
        compareField.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });

        return null;
      }

      return validationMessage;
    };
  }

  /**
   * Validator that requires the control's value to match a predefined password pattern.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static password(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$'));
        if (v(control)) {
          return new ValidationMessage('Password too weak', v);
        }
      }
      return null;
    };
  }

  static formArrayAtLeastOne(value: ObjectLiteral): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const invalid = (control as FormArray).controls.length === 0;
      let message = 'At least one item must be present.';

      if (typeof value === 'object') {
        message = value.message;
      }

      if (invalid) {
        return new ValidationMessage(message, { formArrayAtLeastOne: true });
      }

      return null;
    };
  }

  /**
   * Validator that requires the control have a non-empty value.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static selectLocation(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.required(control);
        if (validator) {
          return new ValidationMessage('Select one of the locations.', validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a regex pattern.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static semanticVersioning(value: string): ValidatorFn {
    const pattern = /^(\d+)\.(\d+)\.(\d+)(?:-([\w.-]+))?(?:\+([\w.-]+))?$/;
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.pattern(pattern);
        if (validator(control)) {
          return new ValidationMessage(`The value of this field did not match the pattern (e.g. "1.2.3-alpha.test+test").`, validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that ensures current control is after compared control for which the id is provided. Provided id must exist in form and formConfig.
   * @param controlId Control id of the control config
   * @param currentControl The control config that validator is assigned
   * @param controls Sibling controls configurations
   * @returns ValidatorFn
   */
  static dateIsAfter(controlId: string, currentControl: any, controls: Array<any>): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (!controlId || !control.value || !control.parent) {
        return null;
      }

      const compareControlConfig = controls.find((control) => control.id === controlId);
      const compareFormControl = control.parent?.get(controlId);
      const compareControlValue = compareFormControl?.value;

      if (!compareControlValue) {
        return null;
      }

      const valid = moment(control.value).isAfter(compareControlValue);

      if (!valid) {
        return new ValidationMessage('form.validations.dateIsAfter', {
          isSameOrAfter: true,
          parameters: {
            currentControl: currentControl.label,
            compareControl: compareControlConfig.label,
          },
        });
      }

      compareFormControl.setErrors(null);
      return null;
    };
  }

  /**
   * Validator that ensures current control is before compared control for which the id is provided. Provided id must exist in form and formConfig.
   * @param controlId Control id of the control config
   * @param currentControl The control config that validator is assigned
   * @param controls Sibling controls configurations
   * @returns ValidatorFn
   */
  static dateIsBefore(controlId: string, currentControl: any, controls: Array<any>): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (!controlId || !control.value || !control.parent) {
        return null;
      }

      const compareControlConfig = controls.find((control) => control.id === controlId);
      const compareFormControl = control.parent?.get(controlId);
      const compareControlValue = compareFormControl?.value;

      if (!compareControlValue) {
        return null;
      }

      const valid = moment(control.value).isBefore(compareControlValue);

      if (!valid) {
        return new ValidationMessage('form.validations.dateIsBefore', {
          isSameOrAfter: true,
          parameters: {
            currentControl: currentControl.label,
            compareControl: compareControlConfig.label,
          },
        });
      }

      compareFormControl.setErrors(null);
      return null;
    };
  }

  /**
   * Validator that requires the control's value to be a integer
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static number(value: number): ValidatorFn {
    const pattern = /^\d+$/;
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.pattern(pattern);
        if (validator(control)) {
          return new ValidationMessage(`Field must be a whole number.`, validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to be in 00:00 format
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static time(value: boolean): ValidatorFn {
    const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.pattern(pattern);
        if (validator(control)) {
          return new ValidationMessage(`Enter correct time format 00:00.`, validator);
        }
      }
      return null;
    };
  }

  static maxFileSize(maxSize: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (!isSet(control.value?.size)) {
        return null;
      }
      const isToLarge = control.value.size / 1048576 > maxSize;

      if (maxSize && isToLarge) {
        return new ValidationMessage(`Upload size limit is ${maxSize} MB`, {
          maxFileSize: true,
        });
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a regex pattern.
   * @param value Configuration value of the validator
   * @param currentControl Control that is been validated
   * @param controls List of controls that #currentControl belongs to
   * @returns ValidatorFn
   */
  static colorValidator(value: string): ValidatorFn {
    const pattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const validator = NgValidators.pattern(pattern);
        if (validator(control)) {
          return new ValidationMessage(`Value must match the color pattern (e.g. "#FFFFFF").`, validator);
        }
      }
      return null;
    };
  }

  /**
   * Validator that checks if the control's value contains any forbidden MCON characters.
   * @param value Configuration value of the validator
   * @returns ValidatorFn
   */
  static forbiddenMCONCharacters(value: any): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      let message = '';

      if (typeof value === 'object') {
        message = value?.message;
      }

      if (isSet(control.value) && this.mconNotValidCharacters.test(control.value)) {
        const validator = NgValidators.pattern(this.mconNotValidCharacters);
        return new ValidationMessage(message, validator);
      }
      return null;
    };
  }
}
