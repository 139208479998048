import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryLoggerService {
  logPageView(pageName: string): void {
    Sentry.addBreadcrumb({
      category: 'navigation',
      message: `Page viewed: ${pageName}`,
      level: 'info',
    });
  }
}
