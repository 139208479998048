import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';
import { LookupResult, LookupService } from 'src/app/modules/quick-forms';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Injectable } from '@angular/core';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { GetAllOptionsDTO } from '@jm-table';
import { camelCaseToWords } from '@util';

import { AbstractCRUDService } from '../abstract-crud.service';
import { ResponseData } from '../abstract/interface/response-data';
import deleteMutation from './gql/delete.graphql';
import getAll from './gql/get-all.graphql';
import oneQuery from './gql/one.graphql';
import { LicenseDTO } from './interfaces/licenses.interface';
import { Licenses } from './models/licenses.model';

@Injectable({ providedIn: 'root' })
export class LicenseService extends AbstractCRUDService<Licenses, LicenseDTO> implements LookupService {
  override modelName = 'license';
  override modelNamePlural = 'licenses';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  override model = Licenses;

  override readonly fields: string = `
    id
    licenseStart
    licenseEnd
    type
    machineSlots
    description
    status
    company {
      id
      name
    }
    machineSlotsAssigned
    machinesAssigned {
      guid
      name
    }
  `;

  override getOne(id: number, options?: ObjectLiteral): Observable<Licenses> {
    const modelName = this.modelName;

    return this.apollo.query<LicenseDTO>({ query: oneQuery, variables: { input: { id }, ...options } }).pipe(
      catchAllErrors((error) => {
        this.handleMessage(error, 'licenses.error.get-one');
      }),
      map((response: ApolloQueryResult<ResponseData<LicenseDTO>>) => {
        return this.loadModel(response.data[modelName]);
      })
    );
  }

  override delete(id: number): Observable<any> {
    const mutation = this.deleteMutation;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          input: {
            id,
          },
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'licenses.error.delete');
        }),
        tap(() => {
          this.snackBarService.success('licenses.success.delete');
        }),
        map((response: ApolloQueryResult<ResponseData<Licenses>>) => {
          return response.data['deleteLicense'];
        })
      );
  }

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<Licenses>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<LicenseDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<LicenseDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override create(input: any): Observable<Licenses> {
    const mutation = gql`
      ${this.createQuery}
    `;

    return this.apollo.mutate<LicenseDTO>({ mutation, variables: { input } }).pipe(
      catchAllErrors((error) => {
        this.handleMessage(error, 'licenses.error.create');
      }),
      tap(() => {
        this.snackBarService.success('licenses.success.create');
      }),
      map((response: ApolloQueryResult<{ [key: string]: LicenseDTO }>) => {
        return this.loadModel(response.data['addLicense']);
      })
    );
  }

  protected override get createQuery(): string {
    return `
        mutation addLicense($input: AddLicenseInput!) {
          addLicense(input: $input) {
            ${this.fields}
          }
        }
    `;
  }

  getSuggestions(searchCriteria: SearchCriteriaDTO): Observable<ListResultDTO<LookupResult>> {
    return this.getAll(searchCriteria).pipe(
      map((value) => {
        return {
          pageDetails: value.pageDetails,
          records: this.mapDataToLookupResult(value.records),
        };
      })
    );
  }

  protected mapDataToLookupResult(licenses: Array<Licenses>): Array<LookupResult> {
    return licenses.map((license) => {
      return {
        id: license.id,
      } as LookupResult;
    });
  }
}
