import { Model } from 'ngx-super-model';

import { Article } from '../../articles/article/models/article.model';
import { MachineDTO } from '../../machines/interfaces/machine.interface.model';
import { OrderStatus } from '../enums';
import { OrderDTO } from '../interfaces/order.interface';
import { ProductionRuns } from './production-runs.model';

export class Order extends Model<OrderDTO> {
  guid?: string | null = null;
  name?: string | null = null;
  quantity?: number | null = null;
  note?: string | null = null;
  article?: Article | null = null;
  status?: OrderStatus | null = null;
  isMovable = false;
  isDequeueable = false;
  test?: boolean | null = null;
  customer?: string | null = null;
  productionUnit?: string | null = null;
  removeOrderAfterWeaving?: boolean | null = null;
  removePatternsAfterWeaving?: boolean | null = null;
  machine?: MachineDTO | null = null;
  orderGuid?: string | null = null;
  type?: string | null = null;
  preselectPicks?: number | null = null;
  remainingPicks?: number | null = null;
  lengthInMeters?: number | null = null;
  progressPercent?: number | null = null;
  secondsRemaining?: number | null = null;
  timeRemaining?: string | null = null;
  productionTime?: string | null = null;
  error?: string | null = null;
  isOrderUnlimited?: boolean | null = null;
  icon?: string | null = null;
  productionRuns: ProductionRuns = new ProductionRuns();
  fixedCost: number | null = null;
  variableCost: number | null = null;
  revenue: number | null = null;
  wastePercentage?: number | null = null;
  usedMaterial: number | null = null;
  co2PerMaterialUnit: number | null = null;
  isMymuenetCreated = false;
  lineNumber: number | null = null;
  createdAt?: string;
  updatedAt?: string;

  getCurrencyCode() {
    return this.machine?.location?.currencyCode;
  }
}
